<template>
  <Main>
    <div class="clients">
      <div class="clients__container">
        <div class="clients__header">
          <h1 class="clients__title">Заказчики</h1>
          <div class="clients__tools">
            <InputSearch
              :label="'search'"
              :search="searchClients"
              :placeholder="'Поиск по имени заказчика'"
            />
            <div class="clients__button">
              <ButtonMain @click.native="handleCreateClientPage()">Создать заказчика</ButtonMain>
            </div>
          </div>
        </div>
        <CustomTable
          v-if="clientsList.length"
          :data="clientsList"
          :deleted-keys="tableOptions.deletedKeys"
          :rename-columns="tableOptions.renameColumn"
          :custom-order="tableOptions.customOrder"
          :is-action-block-visible="tableOptions.isActionBlockVisible"
          :handleFilter="handleFilter"
          :handle-delete="handleDeleteClient"
          :handle-copy="handleModelCopy"
          :handle-edit="handleEdit"
          :direction="directionSort"
          :order="orderSort"
        >
          <template v-slot:footer>
            <div class="pagination">
              <Pagination
                :maxPage="clients.meta.lastPage"
                :page="page"
                :handlePageChange="handlePageChange"
              />
            </div>
          </template>
        </CustomTable>
      </div>
    </div>
    <DefaultModal
      class="clients__confirm-modal"
      v-if="deleteModal"
      :on-close="handleCloseConfirmModal"
    >
      <ConfirmModalContent :on-cancel="handleCloseConfirmModal" :on-success="handleDelete">
        <template v-slot:description>
          <p>Вы уверены, что хотите удалить заказчика?</p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "../constants/routes";

import Main from "../templets/Main.vue";
import CustomTable from "../components/molecules/CustomTable.vue";
import ButtonMain from "../components/atoms/buttons/ButtonMain.vue";
import FetchSpinnerModal from "../components/molecules/FetchSpinnerModal.vue";
import DefaultModal from "../components/molecules/DefaultModal.vue";
import ConfirmModalContent from "../components/molecules/ConfirmModalContent.vue";
import Pagination from "../components/molecules/Pagination.vue";
import InputSearch from "../components/atoms/inputs/InputSearch.vue";

export default {
  name: "Clients",
  data() {
    return {
      rowToDelete: null,
      deleteModal: false,
      directionSort: false,
      orderSort: "id",
      search: "",
      tableOptions: {
        deletedKeys: ["null"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "name",
            to: "ФИО",
          },
          {
            from: "phone",
            to: "Телефон",
          },
          {
            from: "created_at",
            to: "Дата регистрации",
          },
          {
            from: "orders_count",
            to: "Всего заказов",
          },
          {
            from: "total_amount",
            to: "Общая сумма",
          },
        ],
        customOrder: ["id", "name", "phone", "created_at", "orders_count", "total_amount", "null"],
      },
    };
  },
  components: {
    Main,
    CustomTable,
    ButtonMain,
    FetchSpinnerModal,
    DefaultModal,
    ConfirmModalContent,
    Pagination,
    InputSearch,
  },
  methods: {
    handlePageChange(page) {
      this.$router.push({
        path: ROUTE.CLIENTS_PAGE.replace(":page", page),
      });
      this.getClientsList();
    },
    getClientsList() {
      let url = `?page=${this.page}`;
      if (this.orderSort.length) {
        url = url + `&order=${this.orderSort}&direction=${this.directionSort ? "asc" : "desc"}`;
      }
      if (this.search != null && this.search != "") {
        url = url + `&search=${this.search}`;
      }
      this.$store.dispatch("clients/getClientsList", url);
    },
    handleEdit(row) {
      this.$router.push({
        path: ROUTE.CLIENT_CREATE_PAGE.replace(":process", "update").replace(":clientId", row.id),
      });
    },
    handleModelCopy(row) {
      this.$router.push({
        path: ROUTE.CLIENT_DETAIL_PAGE.replace(":clientId", row.id),
      });
    },
    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getClientsList();
    },
    handleCreateClientPage() {
      this.$router.push({
        path: ROUTE.CLIENT_CREATE_PAGE.replace(":process", "create").replace(":clientId", "new"),
      });
    },
    handleDeleteClient(row) {
      this.rowToDelete = row;
      this.deleteModal = true;
    },
    handleDelete() {
      const url = `/${this.rowToDelete.id}`;
      this.$store.dispatch("clients/deleteClient", url).finally(() => {
        this.getClientsList();
      });
      this.handleCloseConfirmModal();
    },
    handleCloseConfirmModal() {
      this.rowToDelete = null;
      this.deleteModal = false;
    },
    searchClients(data) {
      if (this.page != 1) {
        this.$router.push({
          path: ROUTE.CLIENTS_PAGE.replace(":page", 1),
        });
      }
      this.search = data;
      this.getClientsList();
    },
  },
  computed: {
    ...mapState("clients", ["error", "loadingProcess", "clients", "clientsList"]),
    page() {
      return +this.$route.params.page;
    },
  },
  mounted() {
    this.getClientsList();
  },
};
</script>

<style lang="scss" scoped>
.clients {
  width: 100%;

  &__header {
    @include flex-row;

    justify-content: space-between;
  }

  &__button {
    width: 158px;
  }

  &__tools {
    display: flex;

    /deep/.search-input {
      margin-right: 20px;
    }

    /deep/#search {
      width: 300px;
      height: 48px;
    }
  }

  &__container {
    padding: 25px 15px;
  }
}
/deep/.custom-table__header {
  grid-template-columns: 50px 3fr 4fr 3fr 2fr 2fr 3fr !important;
}
/deep/.custom-table__row {
  grid-template-columns: 50px 3fr 4fr 3fr 2fr 2fr 3fr !important;
}
</style>
